import React from "react";
import Helmet from "react-helmet";
import Particles from "react-particles-js";
import { Container } from "react-bootstrap";
import Spinner from "components/spinner";
import { Link } from "gatsby";
import Glitch from "components/glitch";

export default class TEST extends React.Component {
  particles() {
    return (
      <Particles
        className="particles"
        params={{
          particles: {
            number: {
              value: 50,
              density: {
                enable: false,
                value_area: 5000
              }
            },
            line_linked: {
              enable: true,
              opacity: 0.5
            },
            size: {
              value: 1
            }
          },
          retina_detect: true
        }}
      />
    );
  }

  componentDidMount() {
    const script = document.createElement("script");

    script.src =
      "https://app.ecwid.com/script.js?67959007&data_platform=code&data_date=2021-11-08";
    script.async = true;
    script.charSet = "utf-8";

    document.body.appendChild(script);

    const script2 = document.createElement("script");

    script2.innerHTML =
      "xProductBrowser('categoriesPerRow=3','views=grid(20,3) list(60) table(60)','categoryView=grid','searchView=list','id=my-store-67959007');";

    document.body.appendChild(script2);
  }

  // componentDidUpdate() {
  //   console.log("UPDATED");
  //   if (typeof window !== "undefined") {
  //     window.onload = function() {
  //       if (!window.location.hash) {
  //         window.location = window.location + "#terraformShop";
  //         window.location.reload();
  //         if (!document.body) {
  //           window.location.reload();
  //         }
  //       }
  //     };
  //   }
  // }

  render() {
    return (
      <div>
        <Spinner duration={1000} />;
        <Container className="terraformLogo">
          <Link to="/">
            <Glitch text="Terraform" />
          </Link>
          <div id="my-store-67959007"></div>
          <div>
            {/* <script
              data-cfasync="false"
              type="text/javascript"
              src="https://app.ecwid.com/script.js?67959007&data_platform=code&data_date=2021-11-08"
              charSet="utf-8"
            ></script> */}
            <Helmet>
              {/* <script type="text/javascript">
                document.addEventListener('DOMContentLoaded', xProductBrowser(
                "categoriesPerRow=3", `views=grid(20,3) list(60), table(60)`,
                "categoryView=grid", "searchView=list", "id=my-store-67959007" )
                );
              </script> */}
            </Helmet>
          </div>
        </Container>
      </div>
    );
  }
}
